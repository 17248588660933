import { Functions } from "@mui/icons-material";
import React, { Component, useEffect, useState } from "react";
import { getAboutUs } from './apis';
import mapAssets from "../../assets/image/map-assets.jpg";
import "./AboutUs.css";
import Loader from "../Loader/Loader";


const Aboutus = () => {

    const [aboutUs, setAboutUs] = useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        getAboutUsData()
    }, [])

    const getAboutUsData = async () => {
        // setIsLoading(true);
        const response = await getAboutUs()
        if (response) {
            setAboutUs(response.data);
        }
        // setIsLoading(false);
    }


    return (
      <>
        <div className="main-container container">
          <div className="row">
            <h3>About us</h3>

            <div className="col-md-12">
              <img src={mapAssets} className="assets-map-img" />
            </div>
            <div className="col-md-12 mt-4">
              {aboutUs.map((list, index) => {
                return (
                  <div key={index}>
                    <p>{list.body}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </>
    );
}


export default Aboutus;