import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const getPlotDetails = async (id) => {
  try {
    return await request.get(`${endpoints.getPlotDetails}${id}/`);
  } catch (error) {
    return false;
  }
};
export const updateFevStatus = async (params) => {
  return request.post(`${endpoints.updateFevStatus}`, params);
};
export const updatePlotDetailsCount = async (params) => {
  try {
    return await request.post(`${endpoints.getPlotDetails}`, params);
  } catch (error) {
    return false;
  }
};